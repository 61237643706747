/* eslint-disable */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SiderComponent from './../components/SiderComponent';
import HeaderComponent from './../components/HeaderComponent';
import { formatedDealPeriodObject, refresh } from '../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import {
  setGolfClubDetails,
  setTmpCourseId,
  setTmpDealCourses,
  setNewDealCourses,
} from '../redux/actions/golfClubActions';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DeleteFilled, SaveFilled } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/de_DE';
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  Select,
  Checkbox,
  Tree,
  DatePicker,
  notification,
  Popconfirm,
} from 'antd';

import greenfeeDealsApi from '../api/greenfeeDeals';
const { Content } = Layout;
const { RangePicker } = DatePicker;

function ClubVerbuende(props) {
  // SESSION PARAMS
  const sessionBnd = localStorage.getItem('sessionBnd');
  const headers = {
    headers: { Authorization: `Bearer ${sessionBnd}` },
  };
  // REDUX STUFF
  const dispatch = useDispatch();
  const golfClubDetails = useSelector(
    (state) => state.golfClub.golfClubDetails
  );
  const userDetails = useSelector((state) => state.general.userDetails);
  const userRole = userDetails.role;
  const alliances = useSelector((state) => state.general.alliances);
  const courses = useSelector((state) => state.golfClub.tmpDealCourses);
  const coursesNewDeal = useSelector((state) => state.golfClub.newDealCourses);
  const treeData = [];
  const treeDataNewDeal = [];
  let childrenNewDeal = [];
  golfClubDetails.courses.forEach((e) => {
    let subChildrenNewDeal = [];
    e.forEach((e2) => {
      subChildrenNewDeal.push({
        title: e2.standardPricing.dayFee
          ? `Tagesfee`
          : `${e2.standardPricing.numberHoles} Loch`,
        key: e2.standardPricing._id,
      });
    });
    childrenNewDeal.push({
      title: e[0].name,
      key: e[0]._id,
      children: subChildrenNewDeal,
    });
  });
  treeDataNewDeal.push({
    title: 'Alle Plätze',
    key: 'group',
    children: childrenNewDeal,
  });
  let treeDataIndex = 0;

  // SAFETY
  if (!golfClubDetails) {
    props.history.push(`/`);
    return <div></div>;
  }

  // FORM STUFF
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const { Option } = Select;
  const { DirectoryTree } = Tree;
  const dateFormat = 'DD-MM';
  let dateRange = '';
  const golfClubId = golfClubDetails._id.golfClub;

  // TREE STUFF
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedNewKeys, setCheckedNewKeys] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [AllianceValues, setAllianceValues] = useState([]);
  const [alliancesIds, setAlliancesIds] = useState([]);
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheckExisCourses = (keys, event) => {
    const filteredKeys = keys.filter((e) => e !== 'group');
    dispatch(setTmpDealCourses(filteredKeys));
  };
  const onCheckNewCourses = (keys, event) => {
    const filteredKeys = keys.filter((e) => e !== 'group');
    dispatch(setNewDealCourses(filteredKeys));
  };

  // let AllianceValues = [];

  useEffect(() => {
    let newAlliancesIds = [];
    alliances.forEach((e) => {
      newAlliancesIds.push(e._id);
    });

    setAlliancesIds(newAlliancesIds);
  }, [golfClubDetails]);

  // useEffect(() => {
  //   const newAllianceValues = [];
  //   alliancesIds.forEach((id) => {
  //     if (golfClubDetails.alliances && golfClubDetails.alliances.includes(id)) {
  //       newAllianceValues.push(true);
  //     } else {
  //       newAllianceValues.push(false);
  //     }
  //   });
  //   setAllianceValues(newAllianceValues);
  //   console.log('alliances checkbox change');
  // }, [alliancesIds]);

  useEffect(() => {
    if (!alliancesIds || !golfClubDetails?.alliances) return;

    const newAllianceValues = alliancesIds.map((id) =>
      golfClubDetails.alliances.includes(id)
    );

    setAllianceValues(newAllianceValues);
    console.log('Alliance checkbox values updated:', newAllianceValues);
  }, [alliancesIds]);

  const updateGolfClub = async (values) => {
    if (!values.golfClubValues) {
      values.golfClubValues = {};
    }
    values.golfClubValues.alliances = [];
    alliancesIds.forEach((e, i) => {
      if (values.AllianceValues[i] === true) {
        values.golfClubValues.alliances.push(e);
      }
    });

    // Update GolfClub
    await greenfeeDealsApi.patch(
      `/golfclubs/${golfClubId}`,
      values.golfClubValues,
      headers
    );

    // Get Current Values from Server
    const golfClub = await greenfeeDealsApi.post(
      `/golfclubs/golfClubAdmin`,
      { id: golfClubId },
      headers
    );
    if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
      console.log('Error no Golfclub found for your Admin Account');
      return;
    }
    const results = golfClub.data.data.data;

    // Update Redux Store
    dispatch(setTmpDealCourses());
    dispatch(setGolfClubDetails(results));
    notification.success({
      message: 'Update Successful',
      description: 'The golf club details have been updated successfully.',
    });
  };

  // UPDATE Deals
  const saveExisDeal = async (values) => {
    let deal = values.dealValues;
    console.log(deal);
    if (deal.discountType === '%') {
      deal.discountPercent = deal.discountAmount;
      deal.discountPrice = '';
    } else {
      deal.discountPrice = deal.discountAmount;
      deal.discountPercent = '';
    }
    if (values.allianceValues) {
      deal.dealDetails = {};
      const { _id, name } = JSON.parse(values.allianceValues);
      deal.name = name;
      deal.dealDetails.alliance = _id;
      deal.dealDetails.type = 'alliance';
    }
    if (courses !== '') {
      deal.standardPricing = courses;
    }

    if (values.dealPeriod) {
      const dealPeriod = formatedDealPeriodObject(values.dealPeriod);
      deal.dealPeriod = dealPeriod;
    }

    try {
      const updateDeal = await greenfeeDealsApi.patch(
        `/golfclubs/${golfClubId}/deals/${deal._id}`,
        deal,
        headers
      );
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      notification.success({
        message: 'Update Successful',
        description: 'The golf club details have been updated successfully.',
      });
      refresh();
    } catch (error) {}
  };

  // New Deals
  const saveNewDeal = async (values) => {
    let newDeal = values.newDealValues;
    if (newDeal.discountType === '%') {
      newDeal.discountPercent = newDeal.discountAmount;
    } else {
      newDeal.discountPrice = newDeal.discountAmount;
    }
    newDeal.dealDetails = {};
    const { _id, name } = JSON.parse(values.allianceValues);
    newDeal.name = name;
    newDeal.dealDetails.alliance = _id;

    if (checkedNewKeys === undefined || checkedNewKeys[0] === 'group') {
      let newCourses = [];
      golfClubDetails.courses.forEach((e) => {
        newCourses.push(e[0]._id);
      });
      golfClubDetails.standardPricing.forEach((e2) => {
        newCourses.push(e2._id);
      });

      newDeal.standardPricing = newCourses;
    } else {
      newDeal.standardPricing = checkedNewKeys;
    }

    const dealPeriod = formatedDealPeriodObject(values.newDealPeriod);
    newDeal.dealPeriod = dealPeriod;

    newDeal.dealDetails.type = 'alliance';

    try {
      const postDeal = await greenfeeDealsApi.post(
        `/golfclubs/${golfClubId}/deals`,
        newDeal,
        headers
      );
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      form.resetFields();
      setCheckedNewKeys(['group']);
      notification.success({
        message: 'New Deal Save Successfully',
        description: 'The golf club new deal have been saved successfully.',
      });
    } catch (error) {}
  };

  //DELETE deal
  const deleteDeal = async (dealId) => {
    try {
      const postDeal = await greenfeeDealsApi.delete(
        `/golfclubs/${golfClubId}/deals/${dealId}`,
        headers
      );
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      notification.success({
        message: 'Deleted Deal Successfully',
        description: 'The golf club deal have been deleted successfully.',
      });
      //window.location.reload(false);
    } catch (error) {}
  };

  useEffect(() => {
    setCheckedNewKeys(['group']);
    dispatch(setTmpDealCourses());
  }, []);

  const cancel = (e) => {
    console.log(e);
  };

  console.log('ClubVerBundle :::::', alliances);
  console.log('AllianceValues :::::', typeof AllianceValues);
  useEffect(() => {
    if (AllianceValues) {
      form1.setFieldsValue({ AllianceValues });
    }

    console.log('AllianceValues:', AllianceValues);
  }, [AllianceValues]);
  console.log('Initial Form Values:', form1.getFieldsValue());
  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <SiderComponent props={props} />
        <Layout>
          <HeaderComponent />
          <Content
            style={{
              backgroundColor: '#EAEAEA',
              paddingLeft: 50,
              paddingRight: 50,
              paddingBottom: 50,
            }}
          >
            <h3 style={{ paddingTop: 30 }}>Mitgliedschaften in Verbünden</h3>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 15,
                width: '100%',
                padding: 30,
                marginTop: 20,
              }}
            >
              <Form
                onFinish={updateGolfClub}
                hideRequiredMark
                form={form1}
                initialValues={{
                  golfClubValues: golfClubDetails,
                  AllianceValues,
                }}
              >
                <Row gutter={[16, 16]}>
                  {/* {alliances.map((e, i) => (
                    <Col span={6} key={e._id}>
                      <Form.Item
                        name={['AllianceValues', e.id]}
                        valuePropName="checked"
                        style={{ paddingTop: 7 }}
                      >
                        <Checkbox>
                          <h6 style={{ display: 'inline' }}>{e.name}</h6>
                          <br />
                          {e.comment}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  ))} */}
                  {alliances.map((e, i) => {
                    return (
                      <Col span={6} key={e._id}>
                        <Form.Item
                          name={['AllianceValues', i]}
                          valuePropName="checked"
                          style={{ paddingTop: 7 }}
                          key={e._id}
                        >
                          <Checkbox>
                            <h6 style={{ display: 'inline' }}>{e.name}</h6>{' '}
                            <br />
                            {e.comment}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    );
                  })}
                </Row>
                <Row style={{ width: '100%' }} justify="end">
                  {(userRole === 'admin' || userRole === 'golfClubAdmin') && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginTop: 60,
                        marginBottom: 30,
                        marginRight: 30,
                        minWidth: 85,
                        minHeight: 40,
                      }}
                    >
                      Speichern
                    </Button>
                  )}
                </Row>
              </Form>
            </div>
            <h3 style={{ marginTop: 30 }}>Verbünde</h3>
            <h5 style={{ marginTop: 30 }}>Neuen Deal anlegen</h5>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 15,
                width: '100%',
                padding: 30,
                marginTop: 20,
              }}
            >
              <Form
                onFinish={saveNewDeal}
                hideRequiredMark
                form={form}
                initialValues={{
                  newDealValues: {
                    dealDays: {
                      0: 'true',
                      1: 'true',
                      2: 'true',
                      3: 'true',
                      4: 'true',
                      5: 'true',
                      6: 'true',
                    },
                    discountType: '%',
                  },
                }}
              >
                <Row>
                  <Col span={8}>
                    <Row>
                      <Col style={{ width: 180 }}>
                        <Form.Item name={'allianceValues'}>
                          <Select size="small">
                            {alliances.map((alliance) => {
                              // "mein golfclub" is a special case and shall not be in the available options in this dropdown
                              if (alliance.name != 'mein golfclub') {
                                let allianceValue = {
                                  _id: alliance._id,
                                  name: alliance.name,
                                };

                                return (
                                  <Option
                                    value={JSON.stringify(allianceValue)}
                                    key={alliance._id}
                                  >
                                    {alliance.name}
                                  </Option>
                                );
                              }
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col style={{ width: 50 }}>
                        <Form.Item name={['newDealValues', 'discountType']}>
                          <Select size="small">
                            <Option value="%">%</Option>
                            <Option value="€">€</Option>
                            <Option value="-">-</Option>
                            <Option value="+">+</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name={['newDealValues', 'discountAmount']}>
                          <InputNumber size="small" style={{ width: 65 }} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Item name={['newDealPeriod']}>
                        <RangePicker
                          allowClear={false}
                          size="small"
                          format={dateFormat}
                          locale={locale}
                          style={{ width: 275 }}
                        />
                      </Form.Item>
                      <Form.Item
                          name={['newDealValues', 'rollingDeal']}
                          valuePropName="checked"
                          style={{ width: 30, marginLeft: 10 }}
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Rolling</h6>
                          </Checkbox>
                        </Form.Item>
                    </Row>
                  </Col>
                  <Col span={7} className="pricingCol">
                    <Row
                      align="center"
                      style={{
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 0]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Mo</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 1]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Di</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 2]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Mi</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 3]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Do</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 4]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Fr</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 5]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Sa</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 6]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>So/Ft</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={5} className="pricingCol">
                    <Row>
                      <Col span={24} className="pricingCol">
                        <Form.Item name={['newDealValues', 'discountTerms']}>
                          <Input size="small" placeholder="Anmerkung" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <DirectoryTree
                        checkable
                        selectable={false}
                        multiple
                        showIcon={false}
                        onCheck={(keys) => {
                          setCheckedNewKeys(keys);
                        }}
                        onExpand={onExpand}
                        treeData={treeDataNewDeal}
                        checkedKeys={checkedNewKeys}
                      />
                    </Row>
                  </Col>
                  <Col span={4} className="pricingCol">
                    <Row
                      style={{
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Col
                        span={20}
                        style={{
                          height: '100%',
                        }}
                      >
                        <Row
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                              minWidth: 85,
                              minHeight: 40,
                            }}
                          >
                            Speichern
                          </Button>
                        </Row>
                      </Col>

                      <Col span={4}></Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
            <hr
              style={{
                border: '0.3px dotted rgba(0, 0, 0, 0.85)',
                marginTop: 30,
              }}
            />
            <h5 style={{ marginTop: 30 }}>Bestehende Deals editieren</h5>
            {golfClubDetails.deals.map((deal, index) => {
              if (deal.dealDetails.type === 'alliance') {
                if (deal.discountPercent) {
                  deal.discountType = '%';
                  deal.discountAmount = deal.discountPercent;
                } else {
                  if (!deal.discountType) {
                    deal.discountType = '€';
                    deal.discountAmount = deal.discountPrice;
                  } else {
                    deal.discountAmount = deal.discountPrice;
                  }
                }
                let dealPeriod = [];
                if (deal.dealPeriod) {
                  dealPeriod = [
                    moment(deal.dealPeriod.from),
                    moment(deal.dealPeriod.to),
                  ];
                }
                let children = [];
                golfClubDetails.courses.forEach((e) => {
                  let subChildren = [];
                  e.forEach((e2) => {
                    subChildren.push({
                      title: e2.standardPricing.dayFee
                        ? `Tagesfee`
                        : `${e2.standardPricing.numberHoles} Loch`,
                      key: e2.standardPricing._id,
                    });
                  });
                  children.push({
                    title: e[0].name,
                    key: e[0]._id,
                    children: subChildren,
                  });
                });

                treeData.push([
                  {
                    title: 'Alle Plätze',
                    key: 'group',
                    children: children,
                  },
                ]);
                const treeDataMap = treeData[treeDataIndex];
                treeDataIndex++;

                return (
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 15,
                      width: '100%',
                      padding: 30,
                      marginTop: 20,
                    }}
                    key={deal._id}
                  >
                    <Form
                      onFinish={saveExisDeal}
                      hideRequiredMark
                      initialValues={{
                        dealValues: deal,
                      }}
                    >
                      <Form.Item
                        name={['dealValues', '_id']}
                        className="noHeight"
                      >
                        <span />
                      </Form.Item>

                      <Row>
                        <Col span={8}>
                          <Row>
                            <Col style={{ width: 180 }}>
                              <Form.Item name={'allianceValues'}>
                                <Select size="small" defaultValue={deal.name}>
                                  {alliances.map((alliance) => {
                                    // "mein golfclub" is a special case and shall not be in the available options in this dropdown
                                    if (alliance.name != 'mein golfclub') {
                                      let allianceValue = {
                                        _id: alliance._id,
                                        name: alliance.name,
                                      };
                                      return (
                                        <Option
                                          value={JSON.stringify(allianceValue)}
                                          key={alliance._id}
                                        >
                                          {alliance.name}
                                        </Option>
                                      );
                                    }
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col style={{ width: 50 }}>
                              <Form.Item name={['dealValues', 'discountType']}>
                                <Select defaultValue="%" size="small">
                                  <Option value="%">%</Option>
                                  <Option value="€">€</Option>
                                  <Option value="-">-</Option>
                                  <Option value="+">+</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                name={['dealValues', 'discountAmount']}
                              >
                                <InputNumber
                                  size="small"
                                  style={{ width: 65 }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Form.Item name={['dealPeriod']}>
                              <RangePicker
                                allowClear={false}
                                size="small"
                                format={dateFormat}
                                locale={locale}
                                defaultValue={dealPeriod}
                                style={{ width: 295 }}
                              />
                            </Form.Item>
                              <Form.Item
                                name={['dealValues', 'rollingDeal']}
                                valuePropName="checked"
                                style={{ width: 30, marginLeft: 10 }}
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Rolling</h6>
                                </Checkbox>
                              </Form.Item>
                          
                          </Row>
                        </Col>
                        <Col span={7} className="pricingCol">
                          <Row
                            align="center"
                            style={{
                              height: '100%',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 0]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Mo</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 1]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Di</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 2]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Mi</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 3]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Do</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 4]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Fr</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 5]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Sa</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 6]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>So/Ft</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={5} className="pricingCol">
                          <Row>
                            <Col span={24} className="pricingCol">
                              <Form.Item
                                name={['dealValues', 'discountTerms']}
                                id={index}
                              >
                                <Input size="small" placeholder="Anmerkung" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <DirectoryTree
                              checkable
                              selectable={false}
                              multiple
                              showIcon={false}
                              onCheck={(keys) => {
                                dispatch(setTmpCourseId(deal._id));
                                onCheckExisCourses(keys);
                              }}
                              onExpand={onExpand}
                              treeData={treeDataMap}
                              defaultCheckedKeys={deal.standardPricing}
                            />
                          </Row>
                        </Col>
                        <Col span={4} className="pricingCol">
                          <Row
                            style={{
                              alignItems: 'center',
                              height: '100%',
                            }}
                          >
                            <Col
                              span={20}
                              style={{
                                height: '100%',
                              }}
                            >
                              <Row
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '100%',
                                }}
                              >
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  style={{
                                    minWidth: 85,
                                    minHeight: 40,
                                  }}
                                >
                                  Speichern
                                </Button>
                              </Row>
                            </Col>

                            <Col span={4}>
                              <Popconfirm
                                title="Löschen Sie den Deal"
                                description="Wirklich löschen?"
                                icon={
                                  <QuestionCircleOutlined
                                    style={{
                                      color: 'red',
                                    }}
                                  />
                                }
                                onConfirm={() => deleteDeal(deal._id)}
                                onCancel={cancel}
                                okText="Bestätigen"
                                cancelText="Abbrechen"
                              >
                                <Button
                                  danger
                                  type="primary"
                                  icon={<DeleteFilled />}
                                  size="small"
                                />
                              </Popconfirm>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                );
              }
            })}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default ClubVerbuende;
